import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
  TextJustify,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown'
import config from '../website-config';
import { Link } from 'gatsby';
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Obfuscate from 'react-obfuscate';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: ${colors.bodyBackground} //#fff;
  }

  // @media (prefers-color-scheme: dark) {
  //   .site-main {
  //     /* background: var(--darkmode); */
  //     background: ${colors.darkmode};
  //   }
  // }
`;

interface StrapiProps {
  location: Location;
  // pageContext: {
  //   currentPage: number;
  //   numPages: number;
  // };
}

const pageTitle = 'Contatti'
const pageExcerpt = 'Contattaci per maggiori informazioni...'

const Contatti: React.FC<StrapiProps> = props => (
  <IndexLayout>
    <Helmet>
      <html lang={config.lang} />
      <title>{pageTitle} - {config.title}</title>
      <meta name="description" content={pageExcerpt} />
      <meta property="og:site_name" content={config.title} />
      <meta property="og:title" content={`${pageTitle} - ${config.title}`} />
      <meta property="og:description" content={pageExcerpt} />
      <meta property="og:url" content={config.siteUrl + props.location.pathname} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={`${pageTitle} - ${config.title}`} />
      <meta name="twitter:description" content={pageExcerpt} />
      <meta name="twitter:url" content={config.siteUrl + props.location.pathname} />
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">{pageTitle}</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <p>
                  Banda Musicale di Reana del Rojale A.P.S.<br/>
                  Via Vittorio Veneto, 3<br/>
                  33010 Reana del Rojale (UD)<br/>
                  Partita IVA IT01864520307<br/>
                  Codice Fiscale 80023830302<br/>
                  📞 &nbsp;<Obfuscate tel="+393471897586" style={{ display: 'inline-block' }}/><br/>
                  📧 &nbsp;<Obfuscate email="info@bandareana.it" style={{ display: 'inline-block' }}/>
                </p>

                <Form method="POST" action="https://formspree.io/f/mqkwwwnw">
                  
                  <input type="hidden" name="_language" value="it" />

                  <Row>
                    <Col sm={6}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label><small>Nome</small></Form.Label>
                        <Form.Control size="lg" type="text" placeholder="Inserisci il tuo nome" required name="_name"/>
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label><small>Indirizzo email</small></Form.Label>
                        <Form.Control size="lg" type="email" placeholder="indirizzo@mail.com" required name="_replyto"/>
                      </Form.Group>
                    </Col>
                  </Row>
                  
                  
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                    <Form.Label><small>Oggetto</small></Form.Label>
                    <Form.Control size="lg" type="text" placeholder="Inserisci l'oggetto del messaggio" required name="_subject"/>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label><small>Messaggio</small></Form.Label>
                    <Form.Control size="lg" as="textarea" placeholder="Inserisci il testo del messaggio" rows={10} required name="_message"/>
                  </Form.Group>


                  <Row>
                    <Col className="text-center">
                      <Button className="mt-5" variant="primary" type="submit" size="lg">
                        <h6 className="text-white">Invia</h6>
                      </Button>
                    </Col>
                  </Row>
                </Form>   

            
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);


export default Contatti;
